import React from 'react'

export const Percentage = ({ data, name, marginLeft }) => (
  <div style={{ width: `${data}%` }}>
    <div className={`percentage ${name.toLowerCase()}`}></div>
    <div className="valueContainer" style={marginLeft && { marginLeft: -15 }}>
      <h3 className="value">{data}%</h3>
      <h5>{name}</h5>
    </div>
  </div>
)
