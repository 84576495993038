export const getDietValues = (ssObj, getWithPercentage) => {
  const dietsPercentages = {
    estricto: { proteina: 25, grasa: 70, carbohidrato: 5 },
    moderado: { proteina: 30, grasa: 60, carbohidrato: 10 },
    liberal: { proteina: 30, grasa: 50, carbohidrato: 20 },
  }

  const percentageToNumber = (obj) => {
    for (var p in obj) {
      obj[p] = obj[p] / 100
    }
    return obj
  }

  let dietValues = ['Estricto', 'moderado', 'liberal'].filter((e) =>
    ssObj.Diet[0].includes(e)
  )

  if (getWithPercentage)
    return dietValues.map((e) =>
      percentageToNumber(dietsPercentages[e.toLowerCase()])
    )[0]
  else return dietValues.map((e) => dietsPercentages[e.toLowerCase()])[0]
}
