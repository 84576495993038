import { data } from '../data'
import { componentNameToCustomFields } from './componentNameToCustomFields'
import { calculadoraIMC } from './calculateIMC'

export const formatSessionObject = () => {
  let ssObj = {}
  data.forEach((el) => {
    let item = JSON.parse(sessionStorage.getItem(el.componentName))
    if (el.componentName != 'PersonalInfo') {
      let samp = []
      for (let prop in item) {
        if (item[prop] == true) samp.push(prop)
      }
      ssObj[el.componentName] = samp
    } else
      ssObj[el.componentName] = JSON.parse(
        sessionStorage.getItem(el.componentName)
      )
  })
  ssObj.Gender = JSON.parse(sessionStorage.getItem('Gender'))
  delete ssObj.Success
  let formatedObj = calculadoraIMC(ssObj)
  formatedObj.customFields = componentNameToCustomFields
  return formatedObj
}
