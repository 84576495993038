import React from 'react'
import { Navigation } from '../components/Navigation'
import { Footer } from '../components/Footer'
import { Landing } from '../components/Landing'

export const LandingLayout = () => {
  return (
    <main className="landing">
      <Landing />
    </main>
  )
}
