import React from 'react'

export const Baloon = ({ textOne, textBold, textTwo, bg }) => {
  return (
    <div className="baloon">
      <div className="baloonText">
        <span>{textOne}</span>
        <h2>
          <strong>{textBold}</strong>
        </h2>
        <span>{textTwo}</span>
      </div>
      <img src={bg} alt="" className="baloonImg" />
    </div>
  )
}
