import React, { useState, useRef } from 'react'
import { CustomInput } from './CustomInput'

export const TextInput = ({
  label,
  type,
  name,
  placeholder,
  logo,
  units,
  system,
}) => {
  let [input, changeInput] = useState('')
  let [url, setUrl] = useState('')

  const handleInput = (e) => {
    changeInput(e.target.value)
  }

  const willMount = useRef(true)
  if (willMount.current) {
    const images = require.context('./../images/', true)
    let img = images('./' + logo)
    setUrl(img)
  }
  willMount.current = false

  return (
    <div className="text-input-wrapper">
      <img src={url} alt="icon" className="text-input-img" />
      <div className="text-input-input">
        {/* <label htmlFor={name}>{label}</label> */}
        {units ? (
          <CustomInput
            type={type}
            name={name}
            placeholder={placeholder}
            input={input}
            units={units}
            system={system}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            value={input}
            onChange={handleInput}
            required
          />
        )}
      </div>
    </div>
  )
}
