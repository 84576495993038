import React, { useState, useRef } from 'react'

export const RadioInput = ({ name, onResult, logo, isImageOnly }) => {
  let [url, setUrl] = useState('')
  let change = (e) => {
    onResult(name, true, e)
  }

  const willMount = useRef(true)
  if (willMount.current) {
    const images = require.context('./../images/', true)
    let img = images('./' + logo)
    setUrl(img)
  }
  willMount.current = false

  return (
    <button
      className={isImageOnly ? 'list-input' : 'list-input'}
      onClick={change}
      style={isImageOnly ? { width: 190, height: 180 } : {}}
    >
      <img
        src={url}
        className="logo"
        alt="img"
        style={isImageOnly ? { width: 180, top: 5 } : {}}
      />
      {isImageOnly ? '' : name}
    </button>
  )
}
