import React, { useState } from 'react'

export const CustomInput = ({ type, name, placeholder, units, system }) => {
  let [input, changeInput] = useState('')
  const handleInput = (e) => {
    changeInput(e.target.value)
  }

  let [secondInput, changeSecondInput] = useState('')
  const handleSecondInput = (e) => {
    changeSecondInput(e.target.value)
  }

  return (
    <div className="custom-input">
      <input
        type={type}
        min={type == 'number' && '0'}
        placeholder={placeholder}
        name={name}
        value={input}
        data-units={units[system][0]}
        onChange={handleInput}
        required
      />
      <div className="units">{units[system][0]}</div>
      {system == 'imperial' && name == 'altura' ? (
        <>
          <input
            type={type}
            min={type == 'number' && '0'}
            placeholder={placeholder}
            name={name}
            data-units={units[system][1]}
            value={secondInput}
            onChange={handleSecondInput}
            required
          />
          <div className="units">{units[system][1]}</div>
        </>
      ) : null}
    </div>
  )
}
