import React, { useState } from 'react'
import { QuestionBuilder } from './QuestionBuilder'
import { data } from '../data.js'
import { ButtonsContainer } from './ButtonsContainer'
import { useEffect } from 'react'
import { device } from '../helpers/deviceWidth'

export const Form = () => {
  let [step, changeStep] = useState(1)
  let [sel, changeSel] = useState({})
  let [currentComponent, setCurrentComponent] = useState({})

  const next = () => {
    let continueArr = []
    let compoNames = data
      .map((el, i) => el.step == step && el.componentName)
      .filter((e) => e)

    compoNames.forEach((el) => {
      if (!sel[el]) {
        if (el !== 'PersonalInfo') {
          continueArr.push(false)
          data.forEach((e) => {
            if (el == e.componentName)
              if (e.type == 'radio') alert('Por favor, selecciona una opción.')
              else alert('Por favor, selecciona al menos una opción.')
          })
        }
      } else continueArr.push(true)
    })

    if (!continueArr.includes(false)) {
      changeStep(step + 1)
    }
  }

  useEffect(() => {
    changeSel({})
    let current = data
      .map((e) => e.step == step && e.componentName)
      .filter((e) => e)
    setCurrentComponent(current.join())

    current.forEach((e) => {
      sessionStorage.setItem(e, JSON.stringify({}))
    })
  }, [step])

  //this is for validation
  const change = (componentName, field, state, type) => {
    setCurrentComponent(componentName)
    if (!sel[componentName]) sel[componentName] = 0
    state === true
      ? changeSel({
          ...sel,
          [componentName]: sel[componentName] + 1,
        })
      : changeSel({
          ...sel,
          [componentName]: sel[componentName] - 1,
        })
  }

  const prev = () => {
    changeStep(step - 1)
  }

  return (
    <>
      <div
        className={`bgImg ${
          device.isMobile
            ? 'bg-form-mobile'
            : device.isTablet
            ? 'bg-form-tablet'
            : 'bg-form-desktop'
        }`}
      ></div>
      <div className="form-wrapper">
        <section>
          {data.map(
            (el, i) =>
              el.step == step && (
                <QuestionBuilder
                  key={i}
                  element={el}
                  prev={prev}
                  next={next}
                  onChange={change}
                />
              )
          )}
        </section>
        {currentComponent != 'PersonalInfo' && currentComponent != 'Success' ? (
          <section className="under-form">
            <ButtonsContainer
              step={step}
              goBack={prev}
              saveAndContinue={next}
            />
          </section>
        ) : null}
      </div>
    </>
  )
}
