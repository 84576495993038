import { factorTDEE } from './factorTDEE'
import { getDietValues } from './getDietValues'

function formatNumber(num) {
  return Math.round(num * 100) / 100
}

export function calculadoraIMC(ssObj) {
  let fields = ['pesoActual', 'pesoIdeal', 'altura', 'años']
  let exercise = ssObj.Exercise
  let finalObj = {}
  let imb = 0

  ssObj.PersonalInfo.forEach((e, i) => {
    Object.assign(finalObj, e)
  })

  fields.forEach((e) => {
    if (e == 'altura') finalObj[e] = finalObj[e].match(/\d+/g)
    else finalObj[e] = parseInt(finalObj[e].match(/\d+/g)[0])
  })

  if (finalObj.system == 'imperial') {
    let footsToInches =
      parseInt(finalObj.altura[0]) * 12 + parseInt(finalObj.altura[1])
    let inchesToCm = footsToInches * 2.54
    finalObj.altura = inchesToCm
    finalObj.pesoActual = formatNumber(finalObj.pesoActual * 0.453592)
    finalObj.pesoIdeal = formatNumber(finalObj.pesoIdeal * 0.453592)
  } else {
    finalObj.altura = parseInt(finalObj.altura[0])
  }

  if (ssObj.Gender == 'Hombre')
    imb =
      10 * finalObj.pesoActual + 6.25 * finalObj.altura - 5 * finalObj.años + 5
  else
    imb =
      10 * finalObj.pesoActual +
      6.25 * finalObj.altura -
      5 * finalObj.años -
      161

  imb = formatNumber(imb)

  let tdee = formatNumber(imb * factorTDEE[exercise])
  let deficitCalorico = Math.round((tdee * 0.8) / 100) * 100
  finalObj.imb = imb
  finalObj.tdee = tdee
  finalObj.deficitCalorico = deficitCalorico

  let dietValues = getDietValues(ssObj, 'getWithPercentage')

  finalObj.grasa = Math.round((deficitCalorico * dietValues.grasa) / 9)
  finalObj.carbohidrato = Math.round(
    (deficitCalorico * dietValues.carbohidrato) / 4
  )
  finalObj.proteina = Math.round((deficitCalorico * dietValues.proteina) / 4)

  ssObj.PersonalInfo = finalObj

  return ssObj
}
