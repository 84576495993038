import React from 'react'
import { Link } from 'react-router-dom'
import dish from '../images/first/dish.png'
import logo from '../logo.png'
import { device } from '../helpers/deviceWidth'

export const Landing = () => {
  return (
    <div className="landingContainer">
      <div
        className={`bgImg ${
          device.isMobile
            ? 'bg-landing-mobile'
            : device.isTablet
            ? 'bg-landing-tablet'
            : 'bg-landing-desktop'
        }`}
      ></div>
      <div className="dishImg">
        <img src={dish} alt="" />
      </div>
      <nav className="nav">
        <img src={logo} alt="" />
      </nav>

      <div className="textContainer">
        <h1>Obtén tu Plan Personalizado</h1>
        <p>
          de la dieta cetogénicas, solo Responde este cuestionario en menos de{' '}
          <strong>4 minutos</strong> y descubre cuántas calorías tienes que
          consumir para empezar a quemar grasa.
        </p>
        <Link to="/gender">
          <button className="beginBtn">
            <h2>Empieza ahora mismo!</h2>
          </button>
        </Link>
      </div>
    </div>
  )
}
