import React, { useState, useEffect } from 'react'
import { TextInput } from '../TextInput'
import { SystemButtons } from '../SystemButtons'
import { formatForm } from '../../helpers/formatForm'

export const TextInputFactory = ({
  onChange,
  type,
  prev,
  next,
  logos,
  componentName,
  array,
}) => {
  let [system, changeSystem] = useState('metric')

  let systemValue = (e) => {
    changeSystem(e)
  }

  const saveAndContinue = (e) => {
    e.preventDefault()
    onChange(componentName, 'name', true, type)
    let parsedForm = formatForm(e.target, system)
    sessionStorage.setItem(componentName, JSON.stringify(parsedForm))
    next(parsedForm, componentName)
  }

  const goBack = (e) => {
    e.preventDefault()
    prev()
  }

  return (
    <>
      <article className="fieldset">
        <SystemButtons
          form
          changeSystem={changeSystem}
          onResult={systemValue}
        />

        <form className="personal-info-form" onSubmit={saveAndContinue}>
          {array.map((el, i) => (
            <TextInput
              name={el.name}
              logo={logos[i]}
              label={el.label}
              placeholder={el.placeholder}
              type={el.type}
              units={el.units}
              system={system}
              key={i}
            />
          ))}

          <section
            className="buttons-wrapper"
            style={{ margin: '50px auto 20px auto' }}
          >
            <button onClick={goBack} className="prev">
              <h3>Atrás</h3>
            </button>
            <button className="next">
              <h3>Terminar</h3>
            </button>
          </section>
        </form>
      </article>
    </>
  )
}
