import React from 'react'
import { useHistory } from 'react-router-dom'

export const ButtonsContainer = ({
  componentName,
  goBack,
  step,
  saveAndContinue,
}) => {
  const history = useHistory()
  return (
    <section className="buttons-wrapper">
      <button
        onClick={step == 1 ? () => history.push('/gender') : goBack}
        className="prev"
      >
        <h3>Atrás</h3>
      </button>

      {componentName === 'PersonalInfo' ? (
        <button className="next">
          <h3>Terminar</h3>
        </button>
      ) : (
        <button onClick={saveAndContinue} className="next">
          <h3>Siguiente</h3>
        </button>
      )}
    </section>
  )
}
