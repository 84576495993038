// object that goes to the backend.
// each key represents the componentName.

// export const componentNameToCustomFields = {
//   Gender: {
//     id: 1, // fieldName: 'Género',
//     type: 'radio',
//   },
//   Meats: {
//     id: 3, // fieldName: 'Seleeciona lo que quieres comer en tu dieta Keto',
//     type: 'checkbox',
//   },
//   PreferredFoods: {
//     id: 4, // fieldName: 'Entre más alimentos selecciones será mayor la variedad de recetas',
//     type: 'checkbox',
//   },
//   Exercise: {
//     id: 8, //fieldName: '¿Cuál es tu nivel de actividad física?',
//     type: 'radio',
//   },
//   Habits: {
//     id: 7, // fieldName: '¿Cómo es un día típico tuyo?',
//     type: 'radio',
//   },
//   Time: {
//     id: 2, //fieldName: '¿Cuánto tiempo dispones para preparar tus comidas?',
//     type: 'radio',
//   },
//   BodyFat: {
//     id: 9, //fieldName: 'Tu porcentaje de grasa corporal',
//     type: 'radio',
//   },
//   Sleep: {
//     id: 5, // fieldName: '¿Cuántas horas duermes?',
//     type: 'radio',
//   },
//   Diet: {
//     id: 6, // fieldName: '¿Cuál es tu objetivo al realizar esta dieta?',
//     type: 'radio',
//   },
//   PersonalInfo: {
//     pesoActual: {
//       id: 19, // fieldName: 'Peso Actual',
//       type: 'text',
//     },
//     pesoIdeal: {
//       id: 18, // fieldName: 'Meta (Peso al que deseas llegar)',
//       type: 'text',
//     },
//     altura: {
//       id: 20, // fieldName: 'Estatura',
//       type: 'text',
//     },
//     años: {
//       id: 16, // fieldName: 'Edad',
//       type: 'text',
//     },
//     tdee: {
//       id: 10, // fieldName: 'TDEE',
//       type: 'text',
//     },
//     imb: {
//       id: 11, // fieldName: 'BMR',
//       type: 'text',
//     },
//     deficitCalorico: {
//       id: 12, // fieldName: 'Deficit Calorico',
//       type: 'text',
//     },
//     proteina: {
//       id: 13, // fieldName: 'Macro-proteina',
//       type: 'text',
//     },
//     grasa: {
//       id: 14, // fieldName: 'Macro-grasa',
//       type: 'text',
//     },
//     carbohidrato: {
//       id: 15, // fieldName: 'Macro-carbohidrato',
//       type: 'text',
//     },
//   },
// }

// IDs de oviedomarketing

export const componentNameToCustomFields = {
  Gender: {
    id: 7, //- fieldName: '¿Cuál es tu género?',
    type: 'radio',
  },
  Meats: {
    id: 38, //- fieldName: 'Seleeciona lo que quieres comer en tu dieta Keto',
    type: 'checkbox',
  },
  PreferredFoods: {
    id: 39, //- fieldName: 'Entre más alimentos selecciones será mayor la variedad de recetas',
    type: 'checkbox',
  },
  Exercise: {
    id: 21, //- fieldName: '¿Cuál es tu actividad física?',
    type: 'radio',
  },
  Habits: {
    id: 43, //- fieldName: '¿Cómo es un día típico tuyo?',
    type: 'radio',
  },
  Time: {
    id: 37, //- fieldName: '¿Cuánto tiempo dispones para preparar tus comidas?',
    type: 'radio',
  },
  BodyFat: {
    id: 47, //- fieldName: 'Tu porcentaje de grasa corporal',
    type: 'radio',
  },
  Sleep: {
    id: 41, //- fieldName: '¿Cuántas horas duermes?',
    type: 'radio',
  },
  Diet: {
    id: 42, //- fieldName: '¿Cuál es tu objetivo al realizar esta dieta?',
    type: 'radio',
  },
  PersonalInfo: {
    pesoActual: {
      id: 23, //- fieldName: '¿Cuántos pesas? (En kilogramos)'
      type: 'text',
    },
    pesoIdeal: {
      id: 46, //- fieldName: 'Meta (Peso al que deseas llegar)',
      type: 'text',
    },
    altura: {
      id: 45, //- fieldName: 'Estatura',
      type: 'text',
    },
    años: {
      id: 44, //- fieldName: 'Edad',
      type: 'text',
    },
    tdee: {
      id: 27, //- fieldName: 'TDEE',
      type: 'text',
    },
    imb: {
      id: 28, //- fieldName: 'BMR',
      type: 'text',
    },
    deficitCalorico: {
      id: 29, //- fieldName: 'Deficit Calorico',
      type: 'text',
    },
    proteina: {
      id: 30, //- fieldName: 'Macro-proteina',
      type: 'text',
    },
    grasa: {
      id: 31, //- fieldName: 'Macro-grasa',
      type: 'text',
    },
    carbohidrato: {
      id: 32, //- fieldName: 'Macro-carbohidrato',
      type: 'text',
    },
  },
}
