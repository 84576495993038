import React from 'react'
import { RadioFactory } from './FormSteps/RadioFactory'
import { TextInputFactory } from './FormSteps/TextInputFactory'
import { CheckboxFactory } from './FormSteps/CheckboxFactory'
import { Success } from './FormSteps/Success'

export const QuestionBuilder = ({ element, next, prev, onChange }) => {
  const {
    type,
    array,
    title,
    subtitle,
    isImageOnly,
    logos,
    step,
    componentName,
  } = element

  return type === 'radio' ? (
    <RadioFactory
      title={title}
      subtitle={subtitle}
      isImageOnly={isImageOnly}
      array={array}
      logos={logos}
      onChange={onChange}
      type={type}
      step={step}
      componentName={componentName}
    />
  ) : type === 'text' ? (
    <TextInputFactory
      next={next}
      prev={prev}
      isImageOnly={isImageOnly}
      array={array}
      step={step}
      type={type}
      onChange={onChange}
      logos={logos}
      componentName={componentName}
    />
  ) : type === 'checkbox' ? (
    <CheckboxFactory
      componentName={componentName}
      title={title}
      subtitle={subtitle}
      isImageOnly={isImageOnly}
      type={type}
      array={array}
      logos={logos}
      onChange={onChange}
      step={step}
      next={next}
      prev={prev}
    />
  ) : (
    <Success next={next} prev={prev} />
  )
}
