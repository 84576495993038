import { text } from './helpers/textOptions'
import { icons } from './helpers/iconsOptions'

export const data = [
  {
    title: '¿Cuanto tiempo dispones',
    subtitle: 'para preparar tus comidas?',
    componentName: 'Time',
    type: 'radio',
    isImageOnly: false,
    step: '1',
    array: text.time,
    logos: icons.time,
  },
  {
    title: 'Carnes y Pescados',
    subtitle: 'Selecciona lo que quieres comer en tu dieta',
    componentName: 'Meats',
    type: 'checkbox',
    isImageOnly: false,
    step: '2',
    array: text.meats,
    logos: icons.meats,
  },
  {
    title: 'Otros productos que te gustaría incluir:',
    subtitle:
      'A mayor número de productos seleccionados, obtendrás mayores opciones de recetas',
    componentName: 'PreferredFoods',
    type: 'checkbox',
    isImageOnly: false,
    step: '3',
    array: text.preferredFoods,
    logos: icons.preferredFoods,
  },
  {
    title: '¿Cuál es tu nivel de actividad física?',
    subtitle: '',
    componentName: 'Exercise',
    type: 'radio',
    isImageOnly: false,
    step: '4',
    array: text.exercise,
    logos: icons.exercise,
  },
  {
    title: '¿Cuántas horas duermes?',
    subtitle: '',
    componentName: 'Sleep',
    type: 'radio',
    isImageOnly: false,
    step: '5',
    array: text.sleep,
    logos: icons.sleep,
  },
  {
    title: '¿Cuál es tu objetivo al realizar esta dieta?',
    subtitle: '',
    componentName: 'Diet',
    type: 'radio',
    isImageOnly: false,
    step: '6',
    array: text.diet,
    logos: icons.diet,
  },
  {
    title: '¿Cómo es un día típico tuyo?',
    subtitle: '',
    componentName: 'Habits',
    type: 'radio',
    isImageOnly: false,
    step: '7',
    array: text.habits,
    logos: icons.habits,
  },
  {
    title: 'Tu porcentaje de Grasa corporal',
    subtitle: '',
    componentName: 'BodyFat',
    type: 'radio',
    isImageOnly: true,
    step: '8',
    array: text.bodyFat,
    logos: icons.bodyFat,
  },
  {
    title: 'Completa con tu información',
    componentName: 'PersonalInfo',
    type: 'text',
    isImageOnly: false,
    step: '9',
    array: text.personalInfo,
    logos: icons.personalInfo,
  },
  {
    title: 'Resultados',
    componentName: 'Success',
    type: 'success',
    isImageOnly: false,
    step: '10',
  },
]
