import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LandingLayout } from './layouts/LandingLayout'
import { Genders } from './components/Genders'
import { FormLayout } from './layouts/FormLayout'
import './styles/main.scss'

function App() {
  return (
    <Router>
      <Switch>
        <Route path={`/:gender/form`} component={FormLayout} />
        <Route path="/gender" component={Genders} />
        <Route path="/" component={LandingLayout} />
      </Switch>
    </Router>
  )
}

export default App
