import React, { useState, useEffect } from 'react'
import { CheckboxInput } from '../CheckboxInput'

export const CheckboxFactory = ({
  componentName,
  step,
  title,
  subtitle,
  isImageOnly,
  logos,
  array,
  onChange,
  type,
}) => {
  let [answers, setAnswers] = useState({})

  let manageState = (name, state) => {
    onChange(componentName, name, state, type)
    let prev = sessionStorage.getItem(componentName)
      ? JSON.parse(sessionStorage.getItem(componentName))
      : ''
    let toSave = { ...prev, [name]: state }
    sessionStorage.setItem(componentName, JSON.stringify(toSave))
    setAnswers(Object.assign(answers, { [name]: state }))
  }

  useEffect(() => {
    setAnswers({})
  }, [step])

  return (
    <article className="fieldset">
      <h2 className="title">{title}</h2>
      <h3 className="subtitle">{subtitle}</h3>
      <section
        className="checkbox-wrapper"
        style={
          array.length > 5 && window.innerWidth >= 465
            ? { gridTemplateColumns: '1fr 1fr', width: '100%' }
            : { gridTemplateColumns: '1 fr' }
        }
      >
        {array.map((el, i) => (
          <CheckboxInput
            name={el}
            logo={logos[i]}
            onResult={manageState}
            key={i}
          />
        ))}
      </section>
    </article>
  )
}
