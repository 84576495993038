import React from 'react'

export const SliderContainer = ({
  children,
  light,
  strong,
  data,
  flexDirection,
}) => (
  <div className="cont">
    <h2>
      {strong != 'Calorías ' ? (
        <>
          <span className="light">{light}</span>
          <span className="strong">{strong}</span>
        </>
      ) : (
        <>
          <span className="strong">{strong}</span>
          <span className="light">{light}</span>
        </>
      )}
    </h2>

    <div className="sliderbg">
      <div className={`slider ${flexDirection}`}>{children}</div>
    </div>
    <div className="data">{data}</div>
  </div>
)
