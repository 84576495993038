import React from 'react'
import man from '../images/second/man.png'
import woman from '../images/second/woman.png'
import { Link } from 'react-router-dom'
import { device } from '../helpers/deviceWidth'

export const Genders = () => {
  const save = (e) => {
    sessionStorage.setItem('Gender', JSON.stringify([e.target.dataset.gender]))
  }

  return (
    <main className="genderWrapper">
      <div
        className={`bgImg ${
          device.isMobile
            ? 'bg-gender-mobile'
            : device.isTablet
            ? 'bg-gender-tablet'
            : 'bg-gender-desktop'
        }`}
      ></div>
      <div className="genderTitle">
        <h1>Genero</h1>
      </div>

      <div className="btnWrapper">
        <Link to="/Mujer/form">
          <button onClick={save} data-gender="Mujer">
            <img src={woman} alt="Mujer" data-gender="Mujer" />
            <h2>Soy Mujer</h2>
          </button>
        </Link>
        <Link to="/Hombre/form">
          <button onClick={save} data-gender="Hombre">
            <img src={man} alt="Hombre" data-gender="Hombre" />
            <h2>Soy Hombre</h2>
          </button>
        </Link>
      </div>
    </main>
  )
}
