export const formatForm = (e, system) => {
  let parsedForm = Array.from(e)
    .map(
      (element, i) =>
        element.tagName == 'INPUT' && {
          [element.name]: element.dataset.units
            ? `${element.value} ${element.dataset.units}`
            : element.value,
        }
    )
    .flat()
    .filter((e) => e)
    .map((e, i, arr) =>
      e[Object.keys(e)].includes('ft')
        ? undefined
        : i > 1 && Object.keys(e)[0] == Object.keys(arr[i - 1])[0]
        ? {
            altura: `${arr[i - 1]['altura']} ${e['altura']}`,
          }
        : e
    )
    .filter((e) => e)

  parsedForm.push({ system: system })
  return parsedForm
}
