import React from 'react'

export const ProfileItem = ({ logo, desc, title }) => (
  <div className="data">
    <img src={logo} alt="" className="dataImg" />
    <div className="dataText">
      <h2>{desc}</h2>
      <h4>
        <strong>{title}</strong>
      </h4>
    </div>
  </div>
)
