import React, { useState } from 'react'

export const SystemButtons = ({ changeSystem, onResult, form }) => {
  let [metricClass, setMetricClass] = useState('btn-active')
  let [imperialClass, setImperialClass] = useState('btn-inactive')

  const changeSystemValue = (e) => {
    if (e.target.id === 'metrico') {
      setMetricClass('btn-active')
      setImperialClass('btn-inactive')
      changeSystem('metric')
      onResult('metric')
    } else {
      setMetricClass('btn-inactive')
      setImperialClass('btn-active')
      changeSystem('imperial')
      onResult('imperial')
    }
  }

  return (
    <div className={"buttons-wrapper"} style={form && {marginTop: 10}}>
      <button onClick={changeSystemValue} className={metricClass} id="metrico">
        <h3 id="metrico">MÉTRICO</h3>
      </button>
      <button
        onClick={changeSystemValue}
        className={imperialClass}
        id="imperial"
      >
        <h3 id="imperial">IMPERIAL</h3>
      </button>
    </div>
  )
}
