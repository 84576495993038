export const icons = {
  time: ['third/A1.png', 'third/A2.png', 'third/A3.png', 'third/A4.png'],
  meats: [
    'fourth/B1.png',
    'fourth/B2.png',
    'fourth/B3.png',
    'fourth/B4.png',
    'fourth/B5.png',
    'fourth/B6.png',
    'fourth/B7.png',
    'fourth/B8.png',
  ],
  preferredFoods: [
    'fifth/C1.png',
    'fifth/C2.png',
    'fifth/C3.png',
    'fifth/C4.png',
    'fifth/C5.png',
    'fifth/C6.png',
    'fifth/C7.png',
    'fifth/C8.png',
    'fifth/C9.png',
    'fifth/C10.png',
    'fifth/C11.png',
    'fifth/C12.png',
    'fifth/C13.png',
  ],
  exercise: [
    'sixth/D1.png',
    'sixth/D2.png',
    'sixth/D3.png',
    'sixth/D4.png',
    'sixth/D5.png',
  ],
  sleep: [
    'seventh/E1.png',
    'seventh/E2.png',
    'seventh/E3.png',
    'seventh/E4.png',
  ],
  diet: ['eighth/F1.png', 'eighth/F2.png', 'eighth/F3.png'],
  habits: ['ninth/G1.png', 'ninth/G2.png', 'ninth/G3.png'],
  bodyFat: {
    Hombre: [
      'tenth/Hombre/H1.png',
      'tenth/Hombre/H2.png',
      'tenth/Hombre/H3.png',
      'tenth/Hombre/H4.png',
      'tenth/Hombre/H5.png',
      'tenth/Hombre/H6.png',
      'tenth/Hombre/H7.png',
      'tenth/Hombre/H8.png',
      'tenth/Hombre/H9.png',
    ],
    Mujer: [
      'tenth/Mujer/H10.png',
      'tenth/Mujer/H11.png',
      'tenth/Mujer/H12.png',
      'tenth/Mujer/H13.png',
      'tenth/Mujer/H14.png',
      'tenth/Mujer/H15.png',
      'tenth/Mujer/H16.png',
      'tenth/Mujer/H17.png',
      'tenth/Mujer/H18.png',
    ],
  },
  personalInfo: [
    'eleventh/I1.png',
    'eleventh/I2.png',
    'eleventh/I3.png',
    'eleventh/I4.png',
    'eleventh/I5.png',
    'eleventh/I6.png',
  ],
  success: [
    'thirteenth/K1.png',
    'thirteenth/K2.png',
    'thirteenth/K3.png',
    'thirteenth/K4.png',
    'thirteenth/K5.png',
    'thirteenth/K6.png',
  ],
}
