import React, { useEffect, useState } from 'react';
import { ProfileItem } from '../ProfileItem';
import { useHistory } from 'react-router-dom';
import { formatSessionObject } from '../../helpers/formatSessionObject';
import { CircularProgress } from '@material-ui/core';
import { Baloon } from '../Baloon';
import { getSliderData } from '../Sliders/sliders';
import K1 from '../../images/thirteenth/K1.png';
import K2 from '../../images/thirteenth/K2.png';
import K3 from '../../images/thirteenth/K3.png';
import K4 from '../../images/thirteenth/K4.png';
import J1 from '../../images/twelfth/J1.png';
import J2 from '../../images/twelfth/J2.png';
import J3 from '../../images/twelfth/J3.png';
import { SliderContainer } from '../SliderContainer';
import useDate from '../../hooks/useDate';
import useIMC from '../../hooks/useIMC';
import useDiet from '../../hooks/useDiet';

export const Success = () => {
  let [loading, setLoading] = useState(true);
  let [results, setResults] = useState({});
  let [imc, setIMC] = useIMC({ value: 0, desc: '' });
  let [dietValues, setDietValues] = useDiet({});
  let [date, setDate] = useDate({ year: '', month: '', weeks: '' });
  const history = useHistory();

  useEffect(() => {
    let formatedObj = formatSessionObject();
    setResults(formatedObj);
    setDate(formatedObj.PersonalInfo);
    setIMC(formatedObj.PersonalInfo);
    setDietValues(formatedObj);
    console.log(formatedObj);

    fetch('/.netlify/functions/active', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formatedObj)
    })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        } else {
          alert(
            'Hubo un error en el servidor. Intentelo nuevamente más tarde.'
          );
          history.push('/');
        }
      })
      .then((data) => {
        setLoading(false);
      })
      .catch((err) => {
        alert('Hubo un error en el servidor. Intentelo nuevamente más tarde.');
        history.push('/');
      });
  }, []);

  return (
    <div className="fieldset">
      {loading === true ? (
        <div className="loading">
          <CircularProgress />
          <h2>Analizando Perfil . . .</h2>
        </div>
      ) : (
        <div className="successContainer">
          <h1>Según tu perfil, con la Dieta Keto</h1>
          <h1>
            <strong>
              Pesarás {results.PersonalInfo?.pesoIdeal} kilos en {date.weeks}{' '}
              semana{date.weeks >= 2 ? 's' : ''}{' '}
            </strong>
          </h1>
          <div className="baloonsContainer">
            <Baloon
              textOne="Pierdes hasta"
              textBold="-2.4Kgs."
              textTwo="por semana"
              bg={J1}
            />
            <Baloon
              textOne="Lograrás tu Objetivo"
              textBold={date.month}
              textTwo={`de ${date.year}`}
              bg={J2}
            />
            <Baloon
              textOne="Aproximadamente"
              textBold="92%"
              textTwo="de nuestros usuarios han perdido 10kg con el Plan keto"
              bg={J3}
            />
          </div>

          <h3 className="title" style={{ background: '#00b5e0' }}>
            Resumen de tu Perfil
          </h3>

          <div className="profile">
            {[
              { logo: K1, title: 'Género', desc: results.Gender?.[0] },
              {
                logo: K2,
                title: 'Edad',
                desc: results.PersonalInfo?.['años'].toFixed()
              },
              {
                logo: K3,
                title: 'Altura',
                desc: results.PersonalInfo?.['altura'].toFixed()
              },
              {
                logo: K4,
                title: 'Peso (kgs)',
                desc: results.PersonalInfo?.['pesoActual'].toFixed()
              }
            ].map((e) => (
              <ProfileItem
                key={e.logo}
                logo={e.logo}
                title={e.title}
                desc={e.desc}
              />
            ))}
          </div>
          <div className="slidersContainer">
            {getSliderData(imc, results, dietValues).map((e) => (
              <SliderContainer
                light={e.light}
                key={e.strong}
                strong={e.strong}
                flexDirection={e.flexDirection}
                data={e.data}
              >
                {' '}
                {e.children}
              </SliderContainer>
            ))}
          </div>

          <h3 className="title" style={{ background: '#00b5e0' }}>
            <a href="https://planketo.co/orden-de-pagobffvlrw" target="__blank">
              Empieza ahora mismo
            </a>{' '}
          </h3>
        </div>
      )}
    </div>
  );
};
