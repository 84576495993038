export const text = {
  time: [
    'Menos de 15 minutos',
    'Entre 15 y 30 minutos',
    'Aproximadamente 45 minutos',
    'Una hora o más',
  ],
  meats: [
    'Pollo',
    'Pescado',
    'Cerdo',
    'Borrego',
    'Mariscos',
    'Crústraceos',
    'Res / Ternera',
    'Sin Carne',
  ],
  preferredFoods: [
    'Brócoli',
    'Cebolla',
    'Tomate',
    'Champiñones',
    'Calabacín',
    'Colifor',
    'Espárragos',
    'Aguacate',
    'Leche',
    'Huevos',
    'Quesos',
    'Frutas',
    'Frutas secas',
  ],
  exercise: [
    'No hago nada de ejercicio',
    'Generalmente camino',
    'Hago ejercicio de 1 a 2 veces por semana',
    'Hago ejercicio de 3 a 5 veces por semana',
    'Hago ejercicio de 5 a 7 veces por semana',
  ],
  sleep: ['Menos de 5 horas', '5 a 6 horas', '6 a 8 horas', 'Más de 8 horas'],
  diet: [
    'Perder el máximo peso posible (Keto Estricto)',
    'Perder Peso y tonificar tu cuerpo  (Keto moderado)',
    'Mantenerse saludable y en forma   (Keto liberal)',
  ],
  habits: [
    'Estoy la mayor parte del tiempo sentada',
    'Estoy en constante movimiento',
    'Tengo una actividad moderada',
  ],
  bodyFat: {
    Hombre: [
      'Foto 1',
      'Foto 4',
      'Foto 7',
      'Foto 2',
      'Foto 5',
      'Foto 8',
      'Foto 3',
      'Foto 6',
      'Foto 9',
    ],
    Mujer: [
      'Foto 1',
      'Foto 4',
      'Foto 7',
      'Foto 2',
      'Foto 5',
      'Foto 8',
      'Foto 3',
      'Foto 6',
      'Foto 9',
    ],
  },
  personalInfo: [
    {
      label: '¿Cuantos años tienes?',
      name: 'años',
      placeholder: 'Edad',
      units: { metric: ['años'], imperial: ['años'] },
      type: 'number',
    },
    {
      label: '¿Cuanto mides?',
      name: 'altura',
      placeholder: 'Altura',
      type: 'number',
      units: {
        metric: ['cm'],
        imperial: ['ft', 'in'],
      },
    },
    {
      label: '¿Cuanto pesas?',
      name: 'pesoActual',
      placeholder: 'Peso Actual',
      type: 'number',
      units: {
        metric: ['kg'],
        imperial: ['lb'],
      },
    },
    {
      label: '¿Cuantos te gustaría pesar?',
      name: 'pesoIdeal',
      placeholder: 'Meta',
      type: 'number',
      units: {
        metric: ['kg'],
        imperial: ['lb'],
      },
    },
    {
      label: 'Nombre Completo',
      name: 'nombre',
      placeholder: 'Nombre Completo',
      type: 'text',
    },
    {
      label: 'Correo electrónico',
      placeholder: 'Correo electrónico',
      name: 'correo',
      type: 'email',
    },
  ],
}
