import React from 'react'
import { CaloriesSlider } from './CaloriesSlider'
import { WaterSlider } from './WaterSlider'
import { IMCSlider } from './IMCSlider'
import { Percentage } from './Percentage'

export const getSliderData = (imc, results, dietValues) => [
  {
    light: 'Tu ',
    strong: 'IMC',
    data: imc.desc,
    flexDirection: 'column',
    children: (
      <>
        <IMCSlider
          min={0}
          max={100}
          value={imc.value}
          aria-labelledby="range-slider"
        />
        <h3 className="value">{imc.value}</h3>
      </>
    ),
  },
  {
    light: 'por Día ',
    strong: 'Calorías ',
    data: '',
    flexDirection: 'column',
    children: (
      <>
        {results.PersonalInfo && (
          <>
            <CaloriesSlider
              min={0}
              max={3000}
              value={[
                results.PersonalInfo.imb - 600,
                results.PersonalInfo.imb + 600,
              ]}
              aria-labelledby="range-slider"
            />
            <h3 className="value">{results.PersonalInfo.imb}</h3>
          </>
        )}
      </>
    ),
  },
  {
    light: 'Cantidad de ',
    strong: 'Agua',
    data: 'Recomendada al día',
    flexDirection: 'column',
    children: (
      <>
        <WaterSlider min={0} max={2} value={2} aria-labelledby="range-slider" />
        <h3 className="value" id="val">
          2 L
        </h3>
      </>
    ),
  },
  {
    light: 'Tu ',
    strong: 'Plan Keto',
    data: '',
    flexDirection: 'row',
    children: (
      <>
        <Percentage data={dietValues.grasa} name={'Grasas'} />
        <Percentage data={dietValues.proteina} name={'Proteinas'} marginLeft />
        <Percentage
          data={dietValues.carbohidrato}
          name={'Carbohidratos'}
          marginLeft
        />
      </>
    ),
  },
]

export { CaloriesSlider, WaterSlider, IMCSlider }
