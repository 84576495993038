import { withStyles } from '@material-ui/core/styles'
import { Slider } from '@material-ui/core'

export const CaloriesSlider = withStyles({
  root: {
    color: 'green',
    height: 8,
  },
  rail: {
    backgroundColor: 'grey',
    height: 8,
    borderRadius: 4,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#bbb',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
})(Slider)
