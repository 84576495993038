import React from 'react'
import { Navigation } from '../components/Navigation'
import { Footer } from '../components/Footer'
import { Form } from '../components/Form'

export const FormLayout = () => {
  return (
    <main className="landing">
      <Form />
    </main>
  )
}
