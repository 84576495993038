import { useState } from 'react'
import { getDietValues } from '../helpers/getDietValues'

export default (prop) => {
  let [diet, setDietValues] = useState(prop)

  const setDietHook = (formatedObj) => {
    let dietValues = getDietValues(formatedObj)
    setDietValues(dietValues)
  }

  return [diet, setDietHook]
}
