import React, { useRef } from 'react'
import { RadioInput } from '../RadioInput'
import { useParams } from 'react-router-dom'

export const RadioFactory = ({
  componentName,
  title,
  subtitle,
  isImageOnly,
  logos,
  onChange,
  type,
  array,
}) => {
  const refContainer = useRef(0)
  const params = useParams()

  let manageState = (name, state, e) => {
    onChange(componentName, name, state, type)
    focus(e)
    sessionStorage.setItem(componentName, JSON.stringify({ [name]: state }))
  }

  const focus = (e) => {
    if (refContainer.current == 0) {
      refContainer.current = e.target
      refContainer.current.className = 'list-input active'
    } else {
      if (refContainer.current != e.target) {
        refContainer.current.className = 'list-input'
        refContainer.current = e.target
        refContainer.current.className = 'list-input active'
      }
    }
  }

  return (
    <article className="fieldset">
      <h2 className="title">{title}</h2>
      <h3 className="subtitle">{subtitle}</h3>
      <section
        className={isImageOnly ? 'list-wrapper justify' : 'list-wrapper'}
        style={
          isImageOnly
            ? window.innerWidth >= 764
              ? { gridTemplateColumns: '1fr 1fr 1fr' }
              : window.innerWidth >= 470
              ? { gridTemplateColumns: '1fr 1fr' }
              : { gridTemplateColumns: '1fr' }
            : null
        }
      >
        {componentName == 'BodyFat'
          ? array[params.gender].map((el, i) => (
              <RadioInput
                name={el}
                logo={logos[params.gender][i]}
                isImageOnly={isImageOnly}
                onResult={manageState}
                key={i}
              />
            ))
          : array.map((el, i) => (
              <RadioInput
                name={el}
                logo={logos[i]}
                isImageOnly={isImageOnly}
                onResult={manageState}
                key={i}
              />
            ))}
      </section>
    </article>
  )
}
