import React, { useState, useRef } from 'react'

export const CheckboxInput = ({ name, onResult, logo }) => {
  let [selected, changeSelect] = useState(false)
  let [active, changeActiveClass] = useState('')
  let [url, setUrl] = useState('')

  let change = (e) => {
    let activeClass = active === 'active' ? '' : 'active'
    changeActiveClass(activeClass)
    changeSelect(!selected)
    onResult(name, !selected)
  }

  const willMount = useRef(true)
  if (willMount.current) {
    const images = require.context('./../images/', true)
    let img = images('./' + logo)
    setUrl(img)
  }
  willMount.current = false

  return (
    <button className={'checkbox ' + active} onClick={change}>
      <img src={url} className="logo" alt="img" />
      <h3>{name}</h3>
    </button>
  )
}
