import { useState } from 'react'

export default (prop) => {
  let [imcObj, setIMCObj] = useState(prop)

  const getDescription = (imc) => {
    if (imc <= 18.5) return 'Peso Insuficiente'
    if (imc < 18.51 && imc > 24.9) return 'Peso Normal'
    if (imc < 25 && imc > 26.9) return 'Sobrepeso Grado I'
    if (imc < 27 && imc > 29.9) return 'Sobrepeso Grado II (preobesidad)'
    if (imc < 30 && imc > 34.9) return 'Obesidad de tipo I'
    if (imc < 35 && imc > 39.9) return 'Obesidad de tipo II'
    if (imc < 40 && imc > 40.9) return 'Obesidad de tipo III (mórbida)'
    if (imc >= 50) return 'Obesidad de tipo IV (extrema)'
  }

  const setIMCHook = (personalInfo) => {
    let { pesoActual, altura } = personalInfo
    let imc = (pesoActual / ((altura / 100) * (altura / 100))).toFixed(2)
    let desc = getDescription(imc)
    setIMCObj({ value: imc, desc })
  }

  return [imcObj, setIMCHook]
}
