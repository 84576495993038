import { useState } from 'react'
import moment from 'moment'

export default (prop) => {
  let [date, setDate] = useState(prop)

  const setDateHook = (personalInfo) => {
    let weightDiff = (personalInfo.pesoActual - personalInfo.pesoIdeal) * 1000
    let durationInDays = weightDiff / 320
    let weeks = Math.round(durationInDays / 7)
    let estimatedTime = moment().add(durationInDays, 'days')
    const month = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ][estimatedTime.month()]
    const year = estimatedTime.year()
    setDate({ year, month, weeks })
  }

  return [date, setDateHook]
}
